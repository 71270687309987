import { queryAllByClass, queryByClass, rgb2hex } from '../shared/util'
import ScrollToSection from './scroll-to-section'

let nav
let navLinks
let linkList
let sniffAreas

function init () {
  nav = queryByClass('js-styleguide-nav')
  if (!nav) return

  ScrollToSection()
  sniffValues()
  setupNavLinks()
  renderIcons()

  linkList = queryByClass('js-styleguide-links', nav)

  window.addEventListener('scroll', () => {
    let navOffset = nav.getBoundingClientRect().top
    if (navOffset < 0) {
      linkList.style.transform = `translateY(${Math.abs(navOffset) + 'px'})`
    } else {
      linkList.style.transform = `translateY(0px)`
    }
  })
}

function setupNavLinks () {
  navLinks = queryAllByClass('js-styleguide-nav-link', nav)

  navLinks.forEach((link, index) => {
    link.addEventListener('click', event => {
      event.preventDefault()
      resetNavLinks()
      event.target.classList.add('selected')
    })
  })
}

function resetNavLinks () {
  navLinks.forEach(link => {
    link.classList.remove('selected')
  })
}

function sniffValues () {
  sniffAreas = queryAllByClass('js-sniff-area')

  sniffAreas.forEach(sniffArea => {
    let sniffElement = queryByClass('js-sniff', sniffArea)
    let sniffLink = sniffElement.querySelector('a')
    let computed, linkComputed
    let writeFontSizeAreas = queryAllByClass('js-write-font-size', sniffArea)
    let writeFontNameAreas = queryAllByClass('js-write-font-name', sniffArea)
    let writeTextTransformAreas = queryAllByClass('js-write-text-transform', sniffArea)
    let writeFontWeight = queryAllByClass('js-write-font-weight', sniffArea)
    let writeColorAreas = queryAllByClass('js-write-color', sniffArea)
    let writeLinkColorAreas = queryAllByClass('js-write-link-color', sniffArea)
    let writeBgColorAreas = queryAllByClass('js-write-bg-color', sniffArea)

    computed = window.getComputedStyle(sniffElement, null)
    linkComputed = sniffLink ? window.getComputedStyle(sniffLink, null) : undefined

    if (writeFontSizeAreas.length) {
      writeFontSizeAreas.forEach(wf => {
        wf.appendChild(document.createTextNode(computed.getPropertyValue('font-size')))
      })
    }
    if (writeFontNameAreas.length) {
      writeFontNameAreas.forEach(wf => {
        let ff = computed.getPropertyValue('font-family')
        let commaIndex = ff.indexOf(',')
        wf.appendChild(document.createTextNode(ff.substring(0, commaIndex)))
      })
    }
    if (writeTextTransformAreas.length) {
      writeTextTransformAreas.forEach(wf => {
        wf.appendChild(document.createTextNode(computed.getPropertyValue('text-transform')))
      })
    }
    if (writeFontWeight.length) {
      writeFontWeight.forEach(wf => {
        wf.appendChild(document.createTextNode(computed.getPropertyValue('font-weight')))
      })
    }
    if (writeColorAreas.length) {
      writeColorAreas.forEach(wc => {
        wc.appendChild(document.createTextNode(rgb2hex(computed.getPropertyValue('color')).toUpperCase()))
      })
    }
    if (writeLinkColorAreas.length && linkComputed) {
      writeLinkColorAreas.forEach(wc => {
        wc.appendChild(document.createTextNode(rgb2hex(linkComputed.getPropertyValue('color')).toUpperCase()))
      })
    }
    if (writeBgColorAreas.length) {
      writeBgColorAreas.forEach(wc => {
        wc.appendChild(document.createTextNode(rgb2hex(computed.getPropertyValue('background-color')).toUpperCase()))
      })
    }
  })
}

function renderIcons () {
  let iconContainer = queryByClass('js-list-icons'), d = document
  let frag = d.createDocumentFragment()
  let symbols = d.querySelectorAll('body > svg symbol')
  for (var i = 0; i < symbols.length; i++) {
    let symbolId = symbols[i].id
    let svgWrapper = d.createElement('div')
    let svgTitle = d.createElement('div')
    let svg = d.createElementNS('http://www.w3.org/2000/svg', 'svg')
    let use = d.createElementNS('http://www.w3.org/2000/svg', 'use')
    svgWrapper.classList.add('styleguide__svg-wrap')
    svgTitle.classList.add('styleguide__svg-title')
    svgTitle.appendChild(d.createTextNode('#' + symbolId))
    svgTitle.setAttribute('title', '#' + symbolId)
    use.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#' + symbolId)
    svgWrapper.appendChild(svg)
    svgWrapper.appendChild(svgTitle)
    svg.appendChild(use)
    frag.appendChild(svgWrapper)
  }
  iconContainer.appendChild(frag)
}

export default init
