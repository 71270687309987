
import Axios from 'axios/dist/axios'
import Keys from '../shared/pubsub-keys'
import { localStore } from '../shared/storage'

const tokenConfig = {
  headers: {
    'x-csrf-api-token': window.csrf_token
  }
}

export default {
  getCart () {
    return Axios.post('/api/v1/checkout/cart', null, tokenConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response))
  },

  getCount () {
    return Axios.get(`/api/v1/checkout/cart/${cartId()}/totals?countsOnly=true`, tokenConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response))
  },

  getAccessories (npkId) {
    return Axios.get(`/ajaxscript/checkout/getAccessories.php?npkId=${npkId}`, tokenConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response))
  },

  getContent () {
    return Axios.get(`/api/v1/checkout/cart/${cartId()}/content`, tokenConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response))
  },

  getQliroIFrame () {
    return Axios.get(`/api/v1/checkout/payment/${cartId()}/qliroone`, tokenConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response))
  },

  getServices (postCode, isIsland) {
    const query = postCode ? `?postcode=${postCode}&isIsland=${isIsland}` : ''
    return Axios.get(`/api/v1/checkout/cart/${cartId()}/services${query}`, tokenConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response))
  },

  getPaymentOptions () {
    return Axios.get(`/api/v1/checkout/payment/${cartId()}/options`, tokenConfig)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response))
  },

  setSelectedPaymentOptions (paymentId) {
    return Axios.post(`/api/v1/checkout/payment/${cartId()}/setSelectedPaymentOption?payment_id=${paymentId}`, tokenConfig)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response))
  },

  add ({ type, objectId, quantity, parent }) {
    const data = new window.FormData()
    data.append('type', type)
    data.append('object_id', objectId)
    data.append('quantity', quantity || 1)
    if (parent) {
      data.append('parent_id', parent)
    }

    return Axios.post(`/api/v1/checkout/cart/${cartId()}/add`, data, tokenConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response))
  },

  addQuote (quoteId) {
    return Axios.get(`/ajaxscript/updateCartBox.php?insert_quote=true&id=${quoteId}&cart_guid=${cartId()}`, tokenConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response))
  },

  addCustomerInfo ({ info, billing, shipment }) {
    const data = new window.FormData()
    data.append('info', JSON.stringify(info || {}))
    data.append('billing', JSON.stringify(billing || {}))
    data.append('shipment', JSON.stringify(shipment || {}))

    return Axios.post(`/api/v1/checkout/customer/${cartId()}`, data, tokenConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response))
  },

  getCustomerInfo () {
    return Axios.get(`/api/v1/checkout/customer/${cartId()}`, tokenConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response))
  },

  addCampaign (objectId) {
    const data = new window.FormData()
    data.append('code', objectId)

    return Axios.post(`/api/v1/checkout/cart/${cartId()}/addCampaign`, data, tokenConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response))
  },

  setComment (comment) {
    const data = new window.FormData()
    data.append('comment', comment)

    return Axios.post(`/api/v1/checkout/cart/${cartId()}/setComment`, data, tokenConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response))
  },

  updateAmount (rowId, quantity) {
    const data = new window.FormData()
    data.append('quantity', quantity)
    return Axios.post(`/api/v1/checkout/cart/${cartId()}/row/${rowId}/update`, data, tokenConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response))
  },

  remove (rowId) {
    return Axios.delete(`/api/v1/checkout/cart/${cartId()}/row/${rowId}`, tokenConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response))
  },

  removeCampaign (code) {
    const data = new window.FormData()
    data.append('code', code)
    return Axios.post(`/api/v1/checkout/cart/${cartId()}/removeCampaign`, data, tokenConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response))
  },

  validateDiscount (discountCode) {
    function validateCoupon (code) {
      return Axios.get(`/api/v1/checkout/campaign/${cartId()}/validate?code=${code}`, tokenConfig)
    }
    function validateVoucher (code) {
      return Axios.get(`/api/v1/checkout/voucher/${code}/validate`, tokenConfig)
    }

    return Axios.all([validateVoucher(discountCode), validateCoupon(discountCode)])
  },

  clearCart () {
    return Axios.delete(`/api/v1/checkout/cart/${cartId()}/delete`, tokenConfig)
      .then(response => {
        localStore.remove(window.shop_id + Keys.CART)
        Promise.resolve(response.data)
      })
      .catch(error => Promise.reject(error.response))
  },

  validatePayment () {
    return Axios.get(`/api/v1/checkout/cart/${cartId()}/paymentValidation`, tokenConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response))
  },

  getCredit (code) {
    return Axios.get(`/api/v1/b2b/credit?password=${code}`, tokenConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response))
  },

  getTabConfig () {
    return Axios.get(`/api/v1/b2b/tabs/config`, tokenConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response))
  },

  confirmB2bOrder () {
    return Axios.post(`/api/v1/b2b/confirm/${cartId()}`, tokenConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response))
  },

  confirmB2cOrder () {
    return Axios.post(`/api/v1/b2c/confirm/${cartId()}`, tokenConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response))
  },

  importCustomerFromNav (orderNumber) {
    const data = new window.FormData()
    data.append('order_id', orderNumber)
    return Axios.post(`/api/v1/b2b/customer/${cartId()}/navimport`, data, tokenConfig)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response))
  },

  getCalendarSlots () {
    return Axios.get(`/api/v1/checkout/shipping/${cartId()}/calendarSlots`, tokenConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response))
  },

  setSelectedCalendarSlot (slot) {
    const slotData = new window.FormData()
    slotData.append('rowId', slot.rowId)
    slotData.append('slotId', slot.slotId)
    slotData.append('date', slot.date)
    return Axios.post(`/api/v1/checkout/shipping/${cartId()}/setCalendarSlot`, slotData, tokenConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response))
  },

  clearAllCalendarSlots () {
    return Axios.post(`/api/v1/checkout/shipping/${cartId()}/clearCalendarSlots`, {} ,tokenConfig)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response))
  },
  
  clearDeliveries () {
    return Axios.post(`/api/v1/checkout/shipping/${cartId()}/clearDeliveries`, {} ,tokenConfig)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response))
  },
}

function cartId () {
  let cart = localStore.getJSON(window.shop_id + Keys.CART) || {}
  return cart.guid
}