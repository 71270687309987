import { queryAllByClass, queryByClass } from "../shared/util";
import { pushGtmObjectEvent } from "../shared/gtm-tracking";

const accordions = queryAllByClass("js-accordion-extend-header");

function init() {
  const url = window.location.href;
  const accordionContentActive = "accordion-content-active";
  const accordionHeaderActive = "accordion-header-active";
  const specificationSection = document.getElementById("specificationSection");

  if (specificationSection && specificationSection.length > 0) {
    const specificationHeader = specificationSection.querySelector(
      ".accordion-header"
    );
    const specificationContent = specificationSection.querySelector(
      ".accordion-specification"
    );

    if (url.match("specification")) {
      specificationHeader.classList.add(accordionHeaderActive);
      specificationContent.classList.add(accordionContentActive);
      specificationContent.parentNode.style.maxHeight = "fit-content";
    }
  }

  accordions.forEach(accordion => {
    accordion.addEventListener("click", event => {
      const accordionWrapper = event.target.closest(
        ".vip__accordion-content-variant-b"
      );
      const content = queryByClass(
        "js-accordion-open-content",
        accordionWrapper
      );
      const readMoreBtn = queryByClass("read-more-btn", content);
      const chevron = queryByClass("accordion-chevron", accordionWrapper);
      const chevronReview = queryByClass(
        "accordion-chevron-review",
        accordionWrapper
      );
      accordion.classList.toggle(accordionHeaderActive);
      content.classList.toggle(accordionContentActive);
      pushGtmObjectEvent(
        "VIP_ACCORDION",
        accordion.dataset.headerName,
        "click"
      );
      const accordionOpen = content.classList.contains(accordionContentActive);
      if (chevron) {
        chevron.classList.toggle("rotate-chevron");
      }
      if (chevronReview) {
        chevronReview.classList.toggle("rotate-chevron");
      }
      if (readMoreBtn) {
        if (accordionOpen) {
          readMoreBtn.parentNode.classList.add("read-more-btn-toggle");
        } else {
          readMoreBtn.parentNode.classList.remove("read-more-btn-toggle");
        }
      }
    });
  });
}

export default init;
