import {
  queryByClass
} from '../shared/util'
import {
  localStore
} from '../shared/storage'
import {
  saveFavorite
} from './favorites'
import Keys from '../shared/pubsub-keys'
import PubSub from 'pubsub-js'

let saveProduct,
  itemsArray

function init () {
  saveProduct = queryByClass('js-save-product')
  if (!saveProduct) return
  updateList()
  updateUIState(null, doesItemExists())
  setupEventlisteners()
  PubSub.subscribe(Keys.FAVORITE_REMOVED, unsetFavorite)
  PubSub.subscribe(Keys.FAVORITE_ADDED, updateUIState)
}

function updateList () {
  itemsArray = localStore.getJSON('favoriteslist') || []
}

function setupEventlisteners () {
  saveProduct.addEventListener('click', () => {
    saveFavorite(window.favoritesObject)
  })
}

function doesItemExists () {
  let itemExists = itemsArray.filter(testIfExists)
  return itemExists.length > 0
}

function testIfExists (value) {
  return value.prodId == window.favoritesObject.prodId
}

function updateUIState (key, added = true) {
  if (added) {
    saveProduct.classList.add('prod-added')
  } else {
    saveProduct.classList.remove('prod-added')
  }
}

function unsetFavorite (key, itemNPK) {
  if (itemNPK === window.favoritesObject.prodId) {
    saveProduct.classList.remove('prod-added')
    updateList()
  }
}

export default init

export {
  updateUIState
}
