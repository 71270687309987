export default function scrollToY (_scrollTargetY, _speed, _easing) {
  // scrollTargetY: the target scrollY property of the window
  // speed: time in pixels per second
  // easing: easing equation to use

  const scrollY = window.pageYOffset
  const scrollTargetY = _scrollTargetY || 0
  const speed = _speed || 2000
  const easing = _easing || 'easeOutSine'
  let currentTime = 0

  // min time .1, max time .8 seconds
  const time = Math.max(0.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed,
    0.8))

  const PI_D2 = Math.PI / 2
  const easingEquations = {
    easeInSine: pos => -Math.cos(pos * (PI_D2)) + 1,
    easeOutSine: pos => Math.sin(pos * (PI_D2)),
    easeInOutSine: pos => -0.5 * (Math.cos(Math.PI * pos) - 1),
    easeInOutQuint: pos => (pos /= 0.5) < 1 ? 0.5 * Math.pow(pos, 5) : 0.5 * (Math.pow((pos - 2), 5) + 2)
  }

  function tick () {
    currentTime += 1 / 60
    const p = currentTime / time
    const t = easingEquations[easing](p)

    if (p < 1) {
      window.requestAnimationFrame(tick)
      window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t))
    } else {
      window.scrollTo(0, scrollTargetY)
    }
  }

  tick()
}
