export const cookieChangeEventName = "cookieChanged";
const domContentLoadedEventName = "DOMContentLoaded";

export function getCookiesAsObject() {
  return document.cookie.split(";").reduce((res, c) => {
    const [key, val] = c
      .trim()
      .split("=")
      .map(decodeURIComponent);
    try {
      return Object.assign(res, { [key]: JSON.parse(val) });
    } catch (e) {
      return Object.assign(res, { [key]: val });
    }
  }, {});
}

function init() {
  const cookieEvent = new CustomEvent(cookieChangeEventName, {
    detail: {
      cookieValue: document.cookie,
      hasCookieChanged: false,
      checkCookieChange: () => {
        if (cookieEvent.detail.cookieValue != document.cookie) {
          cookieEvent.detail.cookieValue = document.cookie;
          return true;
        } else {
          return false;
        }
      },
      listenForCookieChange: () => {
        setInterval(function() {
          if (cookieEvent.detail.checkCookieChange()) {
            cookieEvent.detail.hasCookieChanged = true;
            cookieEvent.target.dispatchEvent(cookieEvent);
          } else {
            cookieEvent.detail.hasCookieChanged = false;
          }
        }, 1000);
      }
    }
  });

  document.addEventListener(domContentLoadedEventName, function({ target }) {
    target.dispatchEvent(cookieEvent);
  });
}

export default init;
