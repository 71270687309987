import {
  queryByClass,
  queryAllByClass,
  getClosestByClass
} from '../shared/util'

const SHOW_MENU_CLASS = 'show-menu'
const SHOW = 'shown'
const JS_MEGA_MENU = 'js-mega-menu'
const LAZY_LOAD_CLASS = 'js-lazy-load-img'

let mainNav
let menuItems
let megaMenus
let contentBlock
let enteredMenuItem
let openMenu
let closeMegaMenus

function init () {
  mainNav = queryByClass('js-navigation')
  menuItems = queryAllByClass('js-menu-item', mainNav)
  megaMenus = queryAllByClass(JS_MEGA_MENU, mainNav)
  contentBlock = queryByClass('js-content')
  closeMegaMenus = queryAllByClass('js-mega-menu-close', mainNav)

  setupMenu()
}

function setupMenu () {
  contentBlock.classList.remove(SHOW_MENU_CLASS)

  menuItems.forEach(menuItem => {
    menuItem.addEventListener('click', navigationListener)
  })
  closeMegaMenus.forEach(closeMegaMenu => {
    closeMegaMenu.addEventListener('click', closeMenus)
  })
}

function navigationListener (event) {
  event.preventDefault()
  enteredMenuItem = event.target.dataset.menuid
  if (enteredMenuItem === openMenu) {
    return // the menu is already open
  }
  closeMenus()
  setTimeout(function () {
    if (openMenu) {
      closeMenus()
    }
    if (enteredMenuItem) {
      openMega(event.target)
    }
  }, 10)
}

function closeMenuOnOutsideClick (event) {
  if (!getClosestByClass(JS_MEGA_MENU, event.target)) {
    closeMenus()
  }
}

function openMega (menuItem) {
  let menuId = menuItem.dataset.menuid

  if (menuId) {
    let mega = queryByClass(`js-mega-menu-${menuId}`, mainNav)
    if (!mega.dataset.images_loaded) {
      let megaImages = queryAllByClass(LAZY_LOAD_CLASS, mega)
      megaImages.forEach(image => {
        image.src = image.dataset.src
      })
      mega.dataset.images_loaded = true
    }
    menuItem.classList.add(SHOW)
    mega.classList.add(SHOW)
    openMenu = mega.dataset.menuid

    contentBlock.addEventListener('click', closeMenuOnOutsideClick)
  }
}

function closeMenus () {
  for (var i = 0; i < megaMenus.length; i++) {
    menuItems[i].classList.remove(SHOW)
    megaMenus[i].classList.remove(SHOW)
  }
  openMenu = undefined
  contentBlock.removeEventListener('click', closeMenuOnOutsideClick)
}

export default init
