import {
  getClosestByClass
} from '../shared/util'
import PubSub from 'pubsub-js'
import Keys from '../shared/pubsub-keys'

let overlayTriggers,
  overlayBoxes

const SHOW_OVERLAY_CLASS = 'overlay-active';

function init () {
  overlayTriggers = document.querySelectorAll('.js-overlay-box-trigger')
  overlayBoxes = document.querySelectorAll('.js-overlay-box')

  setupOverlayTriggers()
  PubSub.subscribe(Keys.RESIZE_WIDTH, overlayBoxCloseAll)
}

function setupOverlayTriggers () {
  if(overlayTriggers){
    for (var i = 0; i < overlayTriggers.length; i++) {
      // Sets individual parameteres with each click event
      (function () {
        const overlayTrigger = overlayTriggers[i]
        const overlayBox = overlayBoxes[i]
        overlayBoxOnClick(overlayTrigger, overlayBox)
      }())
    }
  }
}

function overlayBoxOnClick (overlayTrigger, overlayBox) {
  overlayTrigger.addEventListener('click', function () {
    if (!overlayBox.classList.contains(SHOW_OVERLAY_CLASS)) {
      overlayBoxOpen(overlayTrigger, overlayBox)
    } else {
      overlayBoxClose(overlayBox)
    }
  })
}

function overlayBoxOpen (overlayTrigger, overlayBox) {
  let overlayBoxPosTop = overlayTrigger.offsetTop + overlayTrigger.offsetHeight
  let overlayBoxPosLeft = overlayTrigger.offsetLeft
  let overlayBoxCloseLink = overlayBox.querySelector(
    '.js-overlay-box-close-link')

  overlayBox.style.top = overlayBoxPosTop + 'px'
  overlayBox.style.left = overlayBoxPosLeft + 'px'
  overlayBox.classList.add(SHOW_OVERLAY_CLASS)
  overlayBoxCloseLink.addEventListener('click', closeLinkClickHandler)
}

function overlayBoxClose (overlayBox) {
  let overlayBoxCloseLink = overlayBox.querySelector(
    '.js-overlay-box-close-link')

  overlayBox.classList.remove(SHOW_OVERLAY_CLASS)
  overlayBoxCloseLink.removeEventListener('click', closeLinkClickHandler)
}

function closeLinkClickHandler (event) {
  let parentOverlayBox = getClosestByClass('js-overlay-box', event.target)
  overlayBoxClose(parentOverlayBox)
}

function overlayBoxCloseAll () {
  for (var i = 0; i < overlayBoxes.length; i++) {
    overlayBoxClose(overlayBoxes[i])
  }
}

export default init
