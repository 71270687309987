import {
  debounce
} from './util'
import PubSub from 'pubsub-js'
import Keys from './pubsub-keys'

const BREAKPOINTS = {
  MOBILE: 'mobile',
  MOBILE_RETINA: 'mobile_retina',
  TABLET: 'tablet',
  PHABLET: 'phablet',
  DESKTOP: 'desktop',
  WIDE: 'wide'
}
let windowWidth

function init () {
  querySize()
  windowWidth = window.innerWidth
  setupResizeHandeler()
}

let debouncedResizeHandler = debounce(() => {
  let breakpoint = querySize()
  PubSub.publish(Keys.RESIZE, breakpoint)
  if (windowWidth !== window.innerWidth) {
    PubSub.publish(Keys.RESIZE_WIDTH)
    windowWidth = window.innerWidth
  }
}, 200)

function setupResizeHandeler () {
  window.addEventListener('resize', debouncedResizeHandler)
}

function querySize () {
  let bpoint = window.getComputedStyle(document.querySelector('body'), ':after')
    .getPropertyValue('content').replace(/"/g, '')
  window.breakpoint = bpoint
  return bpoint
}

function getBreakPoint () {
  return querySize()
}

function isTouch () {
  let result = window.breakpoint === BREAKPOINTS.MOBILE || window.breakpoint ===
    BREAKPOINTS.MOBILE_RETINA || window.breakpoint === BREAKPOINTS.PHABLET ||
    window.breakpoint === BREAKPOINTS.TABLET
  return result
}

export {
  BREAKPOINTS,
  isTouch,
  getBreakPoint,
  setupResizeHandeler
}
export default init
