import {
  queryAllByClass
} from '../shared/util'
import CartService from './cart-service'
import {
  setCart,
  createNewCart
} from './basket'

let addButtons

export default function init () {
  addButtons = queryAllByClass('js-add-quote')

  addButtons.forEach(btn => {
    btn.addEventListener('click', addQuote)
  })
}

function addQuote (event) {
  const quoteId = event.target.dataset.quoteId
  createNewCart()
    .then(() => {
      CartService.addQuote(quoteId)
        .then(res => {
          if (res.hasOwnProperty('action_result') && res.action_result.hasOwnProperty(
              'status')) {
            if (!res.action_result.status) {
              console.error(res.action_result.error_info.message)
              return
            }
            const newCart = {
              guid: res.action_result.cart.guid,
              type: 'quote',
              created: Date.now(),
              count: 1
            }
            setCart(newCart)
          }

          if (res.redirect) {
            const parser = document.createElement('a')
            parser.href = res.redirect
            window.location = parser.pathname
          }
        })
    })
}
