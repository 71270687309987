function init () {
  if (!window.autoComplete) return
  initBrandsearch()
}

function initBrandsearch () {
  new window.autoComplete({
    selector: '#brandsearch',
    source: function (term, response) {
      var r = new XMLHttpRequest()
      r.open('POST', '/ajaxscript/brandsearch.php', true)
      r.setRequestHeader('Content-type',
        'application/x-www-form-urlencoded')
      r.onreadystatechange = function () {
        if (r.readyState != 4 || r.status != 200) return
        response(JSON.parse(r.responseText))
      }
      r.send('q=' + term)
    },

    renderItem: function (item, search) {
      search = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
      var re = new RegExp('(' + search.split(' ').join('|') + ')', 'gi')
      return '<div class="autocomplete-suggestion" data-val="' + item.name +
        '" data-id="' + item.id + '">' + item.name.replace(re,
          '<b>$1</b>') + '</div>'
    },

    onSelect: function (event, term, item) {
      event.preventDefault()
      var id = item.dataset.id

      var r = new XMLHttpRequest()
      r.open('POST', '/ajaxscript/brandsearch.php', true)
      r.setRequestHeader('Content-type',
        'application/x-www-form-urlencoded')
      r.onreadystatechange = function () {
        if (r.readyState != 4 || r.status != 200) return
        var details = JSON.parse(r.responseText)
        var aftersales_data = JSON.parse(details.aftersales_data),
          aftersales_string = ''

        for (var x in aftersales_data) {
          aftersales_string += '<div class="col-md-6">' +
            '<div class="panel panel-default">' +
            '<div class="panel-heading ng-binding">' +
            'Aftersalesafdeling ' + x +
            '</div>' +
            '<div class="panel-body">' +
            '<dl class="dl-horizontal">' +
            '<dt>Telefon:</dt>' +
            '<dd class="ng-binding">' + aftersales_data[x].phone +
            '</dd>' +
            '<dt>Email:</dt>' +
            '<dd>' +
            aftersales_data[x].email +
            '</dd>' +
            '<dt>Text:</dt>' +
            '<dd>' + aftersales_data[x].text + '</dd>' +
            '<dt>Småvarer:</dt>' +
            '<dd class="ng-binding">' + aftersales_data[x].sda +
            '</dd>' +
            '</dl>' +
            '</div>' +
            '</div>' +
            '</div>'
        }

        var html = '<b>' + details.name + '</b><br>' +
          details.address + '<br>' +
          details.zipcode + ' ' + details.city + '(' + details.country +
          ')<br><br>' +
          '<b>Brands:</b> ' + details.brands + '<br>' +
          '<b>Indkøber:</b> ' + details.purchaser + '<br>' +
          '<b>Tlf:</b> ' + details.aftersales_phone + '<br>' +
          '<b>Mail:</b> <a href="mailto:' + details.aftersales_email +
          '">' + details.aftersales_email + '</a><br>' +
          '<b>Website:</b> <a href="' + details.website +
          '" target="_blank">' + details.website + '</a><br><br>' +
          '<b>Eventuelle bemærkninger</b><br>' + details.aftersales_text +
          '<br><br>' + details.sales_text +
          '<div class="row">' +
          aftersales_string +
          '</div>'

        document.getElementById('brandinfo').innerHTML = html
        document.getElementById('brandinfo').style.display = 'block'
      }
      r.send('id=' + id)
    }
  })
}

export default init
