let siteStreamer,
  streamerText,
  closeDetails

const SHOWTRUNCATED = 'show-truncated'
const TRUNCATED = 'truncated'

function init () {
  siteStreamer = document.querySelector('.js-site-streamer')
  if (!siteStreamer) return

  closeDetails = siteStreamer.querySelector('.js-streamer-details-close')
  streamerText = siteStreamer.querySelector('.js-streamer-text')

  if (streamerText.classList.contains(TRUNCATED)) {
    siteStreamer.addEventListener('click', () => {
      siteStreamer.classList.add(SHOWTRUNCATED)
      streamerText.classList.remove(TRUNCATED)
    })
  }

  closeDetails.addEventListener('click', (e) => {
    e.stopPropagation()
    siteStreamer.classList.remove(SHOWTRUNCATED)
    streamerText.classList.add(TRUNCATED)
  })
}

export default init
