import Keys from '../shared/pubsub-keys'
import PubSub from 'pubsub-js'

let searchExpand, searchField, search
const ACTIVE_CLASS = 'search-active'

function init () {
  search = document.querySelector('.js-search-form')
  searchField = search.querySelector('.js-search-field')
  searchExpand = search.querySelector('.js-search-expand')

  searchExpand.addEventListener('click', e => {
    e.preventDefault()
    if (document.body.classList.contains(ACTIVE_CLASS)) {
      hideSearch()
      return
    }
    activateSearch()
  })

  searchField.addEventListener('input', searchListener)

  PubSub.subscribe(Keys.MENU_OPEN, hideSearch)
}

function searchListener (event) {
  PubSub.publish(Keys.SEARCH_SUGGEST, event.target.value)
}

function activateSearch () {
  document.body.classList.add(ACTIVE_CLASS)
  PubSub.publish(Keys.SEARCH_ACTIVE)
  searchField.focus()
}

function hideSearch () {
  document.body.classList.remove(ACTIVE_CLASS)
}

export default init
