import {
  queryByClass,
  queryAllByClass,
  pictureTemplate
} from '../shared/util'

let slides,
  slider,
  activeSlide = -1,
  sliderDotsWrap,
  sliderDots,
  pauseToggling = false,
  slideInterval = 8000,
  timer,
  mouseOutTimer,
  dragging = false,
  dragStart = 0,
  dragEnd = 0,
  leftArrow,
  rightArrow

const SHOW_CLASS = 'show-slide'
const ACTIVE_DOT_CLASS = 'active-dot'

function init () {
  slider = queryByClass('js-slider')
  sliderDotsWrap = queryByClass('js-slider-dots-wrap')

  if (!slider) return

  slides = queryAllByClass('js-slide', slider)

  slides.forEach((slide) => {
    let innerSlide = queryByClass('js-inner-slide', slide),
      images = innerSlide.dataset

    queryByClass('js-inner-slide-anchor', innerSlide).appendChild(
      pictureTemplate(images.large, images.medium, images.retina, images.small)
    )
    innerSlide.addEventListener('mouseenter', mouseEnterListener)
    innerSlide.addEventListener('mouseleave', mouseOutListener)

    if (slides.length > 1) {
      innerSlide.addEventListener('touchstart', onTouchStartListener)
      innerSlide.addEventListener('touchmove', onTouchMoveListener)
      innerSlide.addEventListener('touchend', onMouseUpListener)
    }
  })

  // Setup slider dots
  if (sliderDotsWrap) {
    sliderDots = queryAllByClass('js-slide-single-dot', sliderDotsWrap)

    sliderDots.forEach((dot) => {
      dot.addEventListener('click', sliderDotClick)
    })
  }

  // Setup slider arrows
  leftArrow = queryByClass('js-arrow-left', slider)
  rightArrow = queryByClass('js-arrow-right', slider)

  if (leftArrow && rightArrow) {
    leftArrow.addEventListener('click', () => {
      activeSlide = activeSlide - 1 < 0 ? slides.length - 1 : activeSlide -
        1
      clearTimersAndCallToggleSlides(true)
    })
    rightArrow.addEventListener('click', () => {
      activeSlide = activeSlide + 1 > slides.length - 1 ? 0 : activeSlide +
        1
      clearTimersAndCallToggleSlides(true)
    })
  }

  toggleSlides()
}

function toggleSlides (slideProvided = false) {
  if (!pauseToggling || (pauseToggling && dragging)) {
    if (!slideProvided) {
      activeSlide = (activeSlide >= slides.length - 1) ? 0 : activeSlide + 1
    }

    slides.forEach((slide) => {
      slide.classList.remove(SHOW_CLASS)
    })
    slides[activeSlide].classList.add(SHOW_CLASS)

    if (sliderDotsWrap) {
      sliderDots.forEach((dot) => {
        dot.classList.remove(ACTIVE_DOT_CLASS)
      })
      sliderDots[activeSlide].classList.add(ACTIVE_DOT_CLASS)
    }

    timer = setTimeout(() => {
      toggleSlides()
    }, slideInterval)
  }
}

function mouseEnterListener () {
  clearTimeout(timer)
  clearTimeout(mouseOutTimer)
  pauseToggling = true
}

function mouseOutListener () {
  pauseToggling = false
  mouseOutTimer = setTimeout(toggleSlides, slideInterval / 2)
}

function sliderDotClick (event) {
  activeSlide = parseInt(event.target.dataset.slidenumber) - 1
  clearTimersAndCallToggleSlides()
}

function onTouchStartListener (event) {
  dragging = true
  dragStart = {
    x: event.touches[0].clientX,
    y: event.touches[0].clientY
  }
}

function onTouchMoveListener (event) {
  if (dragging) {
    dragEnd = {
      x: event.touches[0].clientX,
      y: event.touches[0].clientY
    }

    if (isItSwipe()) {
      event.preventDefault()
    }
  }
}

function isItSwipe () {
  let xMovement = Math.abs(dragStart.x - dragEnd.x),
    yMovement = Math.abs(dragStart.y - dragEnd.y)
  return xMovement > yMovement
}

function onMouseUpListener () {
  let moveX, slideWidth
  if (!dragging) return

  moveX = dragStart.x - dragEnd.x
  slideWidth = slider.offsetWidth

  if (moveX > (slideWidth * 0.10)) {
    clearTimersAndCallToggleSlides()
  } else if (moveX < (slideWidth * -0.10)) {
    if (activeSlide === 0) {
      activeSlide = slides.length - 2
    } else if (activeSlide === 1) {
      activeSlide = slides.length - 1
    } else {
      activeSlide = activeSlide - 2
    }

    clearTimersAndCallToggleSlides()
  }

  dragging = false
}

function clearTimersAndCallToggleSlides (slideProvided = false) {
  clearTimeout(timer)
  clearTimeout(mouseOutTimer)
  toggleSlides(slideProvided)
}

export default init
