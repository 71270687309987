import PubSub from 'pubsub-js'
import Keys from '../shared/pubsub-keys'
import {
  getClosestByClass,
  rgbaToRgb,
  queryByClass
} from '../shared/util'
import scrollTo from '../shared/animated-scroll'
import {
  pushGtmEvent
} from '../shared/gtm-tracking'

const SHOWING = 'showing'
const PX = 'px'
const TRANSITION = 'transition-active'
const d = document
const MINIMIZED = 'minimized'

class ExpandableText {
  constructor () {
    this.textBlock = undefined
    this.visibleLines = 0
    this.initHeight = 0
    this.collapsedHeight = 0
    this.showing = false
  }

  init (element) {
    if (!element) return
    this.textBlock = element
    this.data = this.textBlock.dataset
    this.textNode = queryByClass('js-expandable-text', this.textBlock)
    this.textNode.classList.add('text-expand')
    //Buy team insurance table experiment. This will later refactored or removed after the experiment is finished.
    if (this.data.variant){
      if(window.screen.availWidth < 768){
        this.data.lines = 8
      }
    } 
    this.constructDOMelements()
    this.visibleLines = parseInt(this.data.lines) || 5
    this.setInitialHeight(this.data.newLine)
    
    if (this.data.collapseKey) {
      PubSub.subscribe(this.data.collapseKey, this.collapse.bind(this))
    }

    PubSub.subscribe(Keys.RESIZE_WIDTH, this.resetOnResize.bind(this))
  }

  constructDOMelements () {
    let linkContainer = d.createElement('div'),
      bgColor = this.getBackgroundColor(),
      specSection = queryByClass('js-spec-section')
    if(this.data.variant){
      linkContainer.classList.add('text-expand__links', 'text-expand__links--vertical-gradient')
    }
    if(!this.data.variant){
      linkContainer.style.background = this.data.newLine ? `rgb(${bgColor})`
      : `linear-gradient( to right, rgba(${bgColor}, 0) 0%, rgba(${bgColor}, 1) 15%)`
      linkContainer.classList.add('text-expand__links')
    }
    this.toggle = this.createToggle()
    this.toggle.insertBefore(this.createEllipsis(), this.toggle.firstChild)
    linkContainer.appendChild(this.toggle)
    this.toggle.addEventListener('click', this.toggleClickListener.bind(this))
    if (this.data.scrollToSpecs && specSection) {
      linkContainer.appendChild(this.createScrollToSection(specSection))
    }
    this.textNode.appendChild(linkContainer)
  }

  createEllipsis () {
    let span = d.createElement('span')
    span.classList.add('text-expand__ellipsis')
    span.appendChild(d.createTextNode('. . .'))
    return span
  }

  createToggle () {
    let toggle = d.createElement('div'),
      showLabel = d.createElement('span'),
      hideLabel = d.createElement('span')
    toggle.classList.add('text-expand__toggle')
    toggle.classList.add('js-text-expand__toggle')
    showLabel.classList.add('text-expand__show-label')
    showLabel.appendChild(d.createTextNode(this.data.showLabel))
    showLabel.addEventListener('click', () => {
      pushGtmEvent('atb-overlay_read-more')
    })
    hideLabel.classList.add('text-expand__hide-label')
    hideLabel.appendChild(d.createTextNode(this.data.hideLabel))
    hideLabel.addEventListener('click', () => {
      pushGtmEvent('atb-overlay_read-less')
    })
    toggle.appendChild(showLabel)
    toggle.appendChild(hideLabel)

    return toggle
  }

  createScrollToSection (section) {
    let link = d.createElement('a')

    link.classList.add('text-expand__scroll-to')
    link.appendChild(d.createTextNode(this.data.scrollToSpecs))
    link.addEventListener('click', (event) => {
      event.preventDefault()
      let scrollTop = section.offsetTop
      scrollTo(scrollTop, 500, 'easeInOutQuint')
      pushGtmEvent('vip_scroll_to_specs_desktop')
    })

    return link
  }

  resetOnResize () {
    this.textNode.style.height = null
    this.textNode.classList.remove(TRANSITION)
    this.textNode.classList.add(MINIMIZED)
    this.toggle.style.display = 'none'
    this.toggle.classList.remove(SHOWING)
    this.showing = false

    this.setInitialHeight()
  }

  setInitialHeight () {
    let lineHeight = Math.floor(parseFloat(window.getComputedStyle(this.textNode,
        null).getPropertyValue('line-height'))),

    visibleLinesModifyer = this.data.newLine ? 0 : 1
    this.collapsedHeight = lineHeight * this.visibleLines
    this.initHeight = this.textNode.offsetHeight + lineHeight
    this.textNode.classList.add(MINIMIZED)
    if (this.initHeight > lineHeight * (this.visibleLines +
        visibleLinesModifyer)) {
      this.toggle.style.display = 'inline-block'
      this.textNode.classList.add(TRANSITION)
      this.textNode.style.height = this.collapsedHeight + PX
    }
  }

  getBackgroundColor () {
    let colorfullParent = getClosestByClass('js-sniff-color', this.textBlock)

    if (colorfullParent) {
      let bgColor = window.getComputedStyle(colorfullParent).getPropertyValue(
        'background-color')

      return (bgColor === 'rgba(0, 0, 0, 0)' || bgColor === 'transparent')
        ? '255, 255, 255' : rgbaToRgb(bgColor)
    }
    return '255, 255, 255'
  }
  
  toggleClickListener (event) {
    event.preventDefault()
    event.stopPropagation()
    if (!this.showing) {
      this.expand(event.currentTarget)
    } else {
      this.collapse(event.currentTarget)
    }
    /* BEGIN OPTICON 2022Q4 Accessories Highlighter Experiment */
    const toggles = document.getElementsByClassName("js-priority-explainer-toggle");
    let i = 0;
    while(toggles[i]) {
      let j = 0;
      const currentToggle = toggles[i]
      while(currentToggle.children[1].children[j]) {
        const readMoreElements = currentToggle.children[1].children[j].children[0];
        if (event.currentTarget == readMoreElements) {
          if (currentToggle.children[0].style.display === "flex") {
            currentToggle.children[0].style.display = "none";
          } else {
            currentToggle.children[0].style.display = "flex";
          }
        }
        j++;
      }
      i++;
    }
    /* END Experiment */
  }
  
  expand () {
    this.textNode.style.height = this.initHeight + 10 + PX
    this.textNode.classList.remove(MINIMIZED)
    this.toggle.classList.add(SHOWING)
    this.showing = true
    this.toggle.parentElement.classList.remove('text-expand__links--vertical-gradient')
  }

  collapse () {
    this.textNode.style.height = this.collapsedHeight + PX
    this.textNode.classList.add(MINIMIZED)
    this.toggle.classList.remove(SHOWING)
    
    this.showing = false
    this.data = this.textBlock.dataset
    if(this.data.variant){
      this.toggle.parentElement.classList.add('text-expand__links--vertical-gradient')
    }
  }
}

export default ExpandableText
