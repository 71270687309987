const keys = {
  RESIZE: 'resize',
  RESIZE_WIDTH: 'resize-width',
  SCROLL: 'scroll',
  SEARCH_ACTIVE: 'search-active',
  MENU_OPEN: 'menu-open',
  PRODUCT_REMOVED: 'product-removed',
  SEARCH_SUGGEST: 'search-suggest',
  OPEN_GALLERY: 'gallery-open',
  CLOSE_GALLERY: 'gallery-close',
  CHANGE_SLIDE: 'gallery-slide',
  REVIEW_SLIDE: 'review-slide',
  ENERGY_LABEL_CLICKED: 'energy-tab',
  ENERGY_LABEL_TEXT_CLICKED: 'datasheet-tab',
  FAVORITE_ADDED: 'favorite-added',
  FAVORITE_REMOVED: 'favorite-removed',
  CART_COUNT: 'added-products',
  CART_ID: 'cartId',
  CART: 'cart',
  SEARCH_EXECUTE: 'execute-search'
}

export default keys
