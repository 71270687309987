import CartService from './cart-service'
import { getCartId, setBasketCount } from './basket'
export const setupAddBtn = btn => {
  btn.addEventListener('click', event => {
    addProductToCart(event.target)
  })
}
export function addProductToCart (target) {
  target.classList.add('adding')
  const params = {
    objectId: target.dataset.npkid,
    prodId: target.dataset.prodid,
    brand: target.dataset.brand,
    type: 'product'
  }
  getCartId().then(
    CartService.add(params).then(() => {
      setBasketCount()
      target.classList.remove('adding')
      target.classList.add('added')
    })
  )
}
