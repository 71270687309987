import Breakpoint from "./shared/breakpoints";
import onePageCofUtil from "./modules/one-page-checkout-util";
import MegaMenu from "./modules/mega-menu";
import OffCanvasMenu from "./modules/off-canvas-menu";
import Search from "./modules/search";
import Streamer from "./modules/streamer";
import SearchSuggest from "./modules/search-suggest";
import Slider from "./modules/slider";
import InhouseNav from "./modules/inhouse-nav";
import OverlayBox from "./modules/overlay-box";
import TakeOver from "./modules/takeover";
import Favorites from "./modules/favorites";
import FavoritesSave from "./modules/favorites-save";
import ChatTrigger from "./modules/chat-trigger";
import PopOver from "./modules/popover";
import Basket from "./basket/basket";
import ExtraPay from "./extrapay/extrapay";
import countdown from "./modules/countdown";
import quotes from "./basket/add-quote";
import StyleGuide from "./modules/styleguide";
import TextExpand from "./modules/text-expand";
import VideoOverlay from "../javascript/modules/video-overlay";
import Accordion from "./modules/accordion";
import AccordionExpandButton from "./modules/accordion-expand-button";
import AccordionExpandToggle from "./modules/accordion-text-expand";
import { setupGtmClickTracking } from "./shared/gtm-tracking";
import NewsletterSignup from "./modules/newsletter-signup";
import Replacement from "./modules/replacement";
import CookieCheck from "./modules/cookieCheck";
import Monitoring from "./modules/monitoring";

export default function init() {
  Breakpoint();
  onePageCofUtil();
  MegaMenu();
  OffCanvasMenu();
  Search();
  Streamer();
  Basket();
  Slider();
  InhouseNav();
  OverlayBox();
  TakeOver();
  Favorites();
  FavoritesSave();
  setupGtmClickTracking();
  ChatTrigger();
  PopOver();
  ExtraPay();
  countdown();
  quotes();
  StyleGuide();
  setUpTextExpand();
  VideoOverlay();
  Accordion();
  AccordionExpandButton();
  AccordionExpandToggle();
  NewsletterSignup();
  Replacement();
  SearchSuggest();
  CookieCheck();
  Monitoring();
}

function setUpTextExpand() {
  let expandBlocks = document.querySelectorAll(".js-expandable-block");
  for (var i = 0; i < expandBlocks.length; i++) {
    let block = new TextExpand();
    block.init(expandBlocks[i]);
  }
}
