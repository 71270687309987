// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
    let timeout;
    return () => {
        let context = this;
        let args = arguments;
        let later = () => {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        window.clearTimeout(timeout);
        timeout = window.setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function throttle(func, interval) {
    let wait = false;
    return () => {
        if (!wait) {
            func.call();
            wait = true;
            window.setTimeout(() => {
                wait = false;
            }, interval);
        }
    };
}

function queryByClass(className, element) {
    let result = queryAllByClass(className, element);
    return result.length ? result[0] : undefined;
}

function queryAllByClass(className, element) {
    let context = element || document;
    return [].slice.call(context.getElementsByClassName(className));
}

function pictureTemplate(largeImg, mediumImg, smallImgRetina, smallImg) {
    let pic = document.createElement("picture");
    pic.innerHTML = `<source srcset="${largeImg}" media="(min-width: 1024px)"><source srcset="${mediumImg}" media="(min-width: 480px)"><img srcset="${smallImg} 1x, ${smallImgRetina} 2x" alt="…"></picture>`;
    return pic;
}

// Get the equivalent unit of vmin (css unit)
function getVmin() {
    let smallest =
        window.innerHeight < window.innerWidth ?
        window.innerHeight :
        window.innerWidth;
    return smallest / 100;
}

// Get the first element up the tree with a chosen class
function getClosestByClass(className, elem) {
    for (; elem && elem !== document && elem.nodeType === 1; elem = elem.parentNode) {
        if (elem.classList.contains(className)) {
            return elem;
        }
    }
    return null;
}

// Get the first element up the tree with chosen slector
function getClosest(selector, elem) {
    let firstChar = selector.charAt(0);

    // Get closest match
    for (; elem && elem !== document && elem.nodeType === 1; elem = elem.parentNode) {
        // If selector is a class
        if (firstChar === ".") {
            if (elem.classList.contains(selector.substr(1))) {
                return elem;
            }
        }

        // If selector is an ID
        if (firstChar === "#") {
            if (elem.id === selector.substr(1)) {
                return elem;
            }
        }

        // If selector is a tag
        if (elem.tagName.toLowerCase() === selector) {
            return elem;
        }
    }
    return null;
}

function formatNumber(number, decimals = 2) {
    return parseFloat(parseFloat(number).toFixed(decimals)).toLocaleString(
        "da-DK", {
            minimumFractionDigits: decimals
        }
    );
}

function rgbaToRgb(rgbaColor) {
    let color, colorRgbArray;

    color = rgbaColor.replace(/[\s+()+rgba]/g, "");
    colorRgbArray = color.split(",");
    color = colorRgbArray[0] + ", " + colorRgbArray[1] + ", " + colorRgbArray[2];

    return color;
}

function rgb2hex(rgb) {
    rgb = rgb.match(
        /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
    );
    return rgb && rgb.length === 4 ?
        "#" +
        ("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
        ("0" + parseInt(rgb[2], 10).toString(16)).slice(-2) +
        ("0" + parseInt(rgb[3], 10).toString(16)).slice(-2) :
        "";
}

function getOffset(el) {
    let rect = el.getBoundingClientRect();
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {
        top: rect.top + scrollTop,
        left: rect.left + scrollLeft
    };
}

function preventBodyScroll() {
    let bodyScrollTop = window.pageYOffset;
    window.requestAnimationFrame(() => {
        document.body.style.position = "fixed";
        document.body.style.top = "-" + bodyScrollTop + "px";
    });
}

function enableBodyScroll() {
    let bodyScrollTop = Math.abs(parseInt(document.body.style.top));
    window.requestAnimationFrame(() => {
        document.body.style.top = null;
        document.body.style.position = "relative";
        window.scrollTo(0, bodyScrollTop);
    });
}

function chunkArray(arr, chunkSize) {
    let chunks = [];
    let i = 0;
    let n = arr.length;

    while (i < n) {
        chunks.push(arr.slice(i, (i += chunkSize)));
    }
    return chunks;
}

//Helper function to check if an element is in the viewport
//Returns true if:
//It’s position from the top and left are greater than or equal to 0.
//It’s distance from the right are less than or equal to the total width of the viewport.
//It’s distance from the bottom is less than or equal to the height of the viewport.
function isInViewport(elem) {
    let bounding = elem.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
}

function isAtBottomOfPage() {
    return ((window.innerHeight + window.scrollY) >= document.body.scrollHeight);
}

function isAtTopOfPage() {
    return (window.scrollY <= 0);
}

export {
    debounce,
    throttle,
    queryByClass, 
    queryAllByClass,
    pictureTemplate,
    getVmin,
    getClosestByClass,
    getClosest,
    formatNumber,
    rgbaToRgb,
    rgb2hex,
    getOffset,
    preventBodyScroll,
    enableBodyScroll,
    chunkArray,
    isInViewport,
    isAtBottomOfPage,
    isAtTopOfPage
};