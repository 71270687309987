import {
  queryByClass
} from '../shared/util'

let popoverTrigger
let popoverContent

function init () {
  popoverTrigger = queryByClass('js-popover-trigger')
  if (!popoverTrigger) return

  popoverContent = queryByClass('js-popover-content')
  if (!popoverContent) return

  // Check if popver should show or not
  popoverTrigger.addEventListener('click', listenToTrigger)
}

function listenToTrigger (event) {
  event.preventDefault()
  popoverContent.classList.toggle('popover-show')

  let triggerTopPosition
  let triggerLeftPosition

  triggerTopPosition = popoverTrigger.offsetTop
  triggerLeftPosition = popoverTrigger.offsetLeft

  popoverContent.style.top = triggerTopPosition + 24 + 'px'
  popoverContent.style.left = triggerLeftPosition + 'px'
}

export default init
