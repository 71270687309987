import { sessionStore } from '../shared/storage'

function init () {
  let cookieVal = document.cookie.replace(/(?:(?:^|.*;\s*)cof_checkout_process\s*=\s*([^;]*).*$)|^.*$/, '$1')
  if (sessionStore.set('onePageCof', cookieVal === 'onepage' || window.onepage)) {
    return cookieVal
  }
}

export default init
