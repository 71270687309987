import { queryAllByClass } from "../shared/util";
import { pushGtmObjectEvent } from '../shared/gtm-tracking'
const toggleAccordionLengths = queryAllByClass('vip-accordion-content-extended-text')

function init() {
    const expandButtons = queryAllByClass("read-more-btn")

    expandButtons.forEach((button, index) => {
        const toggleAccordionLength = toggleAccordionLengths[index]

        button.addEventListener('click', event => {
          pushGtmObjectEvent("VIP_ACCORDION_READMORE", event.target.dataset.headerName, "click");
          toggleAccordionLength.style.maxHeight = 'fit-content'
          event.target.parentNode.classList.remove("read-more-btn-toggle")
        })
      })
}

export default init;