import { pushGtmObjectEvent } from '../shared/gtm-tracking'
import { queryAllByClass } from '../shared/util'
let accordions = queryAllByClass('js-accordion')
let toggleIcons = queryAllByClass('js-toggle-icon')
function init () {
  accordions.forEach((accordion, index) => {
    let toggleIcon = toggleIcons[index]
    accordion.addEventListener('click', event => {
      let accordionContent = accordion.nextElementSibling
      accordion.classList.toggle('active')
      toggleIcon.classList.toggle('active')
      accordionContent.classList.toggle('active')
      if (accordionContent.style.maxHeight) {
        accordionContent.style.maxHeight = null
      } else {
        if(accordionContent.dataset.maxheight) {
          accordionContent.style.maxHeight = accordionContent.dataset.maxheight;
        } else {
          accordionContent.style.maxHeight = accordionContent.scrollHeight + 'px'
        }
      }
    })
  })
}
export default init
