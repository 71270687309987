import {
  queryByClass,
  preventBodyScroll,
  enableBodyScroll
} from '../shared/util'
import { sessionStore, localStore } from '../shared/storage'
import Keys from '../shared/pubsub-keys'
import PubSub from 'pubsub-js'
import {
  setupAddBtn
} from '../basket/add-to-cart-simple'
let itemsArray, favoritesList, listWrapper, closeList, favoritesWidget, wordbook, isNewCof
const d = document

function init () {
  isNewCof = sessionStore.getJSON('onePageCof')
  favoritesWidget = queryByClass('js-favorites-widget')
  if (!favoritesWidget) return
  favoritesList = queryByClass('js-favorites-list')
  closeList = queryByClass('js-close-favorites')
  listWrapper = queryByClass('js-favorites-full')
  updateCount()
  setupEventlisteners()
  wordbook = window.wordbookObject
  window.addEventListener('storageHeader', () => {
    // local storage updated by VueJS
    updateCount()
  })
}

function updateCount () {
  populateItems()
  countItems()
}

function populateItems () {
  itemsArray = localStore.getJSON('favoriteslist') || []
}

function setupEventlisteners () {
  closeList.addEventListener('click', () => {
    listWrapper.classList.remove('show-list')
    enableBodyScroll()
  })

  listWrapper.addEventListener('click', (event) => {
    if (event.target === listWrapper) {
      listWrapper.classList.remove('show-list')
      enableBodyScroll()
    }
  })
}

function renderList () {
  let fragment = d.createDocumentFragment()
  while (favoritesList.firstChild) favoritesList.removeChild(favoritesList.firstChild)

  for (var i = 0; i < itemsArray.length; i++) {
    let item = listItemTemplate(itemsArray[i], i)
    fragment.appendChild(item)
  }
  favoritesList.appendChild(fragment)
}

function openList () {
  renderList()
  listWrapper.classList.add('show-list')
  preventBodyScroll()
}

function listItemTemplate (favorite, itemIndex) {
  let listItem = d.createElement('div'),
    btnWrap = d.createElement('div'),
    btnInfo = d.createElement('a'),
    anchor = d.createElement('a'),
    img = d.createElement('img'),
    brand = d.createElement('div'),
    npkid = d.createElement('div'),
    deleteBtn = d.createElement('div'),
    tickWrapper = d.createElement('div'),
    deleteBtnSvg = d.createElementNS('http://www.w3.org/2000/svg', 'svg'),
    deleteBtnUse = d.createElementNS('http://www.w3.org/2000/svg', 'use'),
    tickMarkSvg = d.createElementNS('http://www.w3.org/2000/svg', 'svg'),
    tickMarkUse = d.createElementNS('http://www.w3.org/2000/svg', 'use'),
    brandLogo = d.createElement('img')

  listItem.classList.add('favorites-list__item')
  listItem.setAttribute('data-testid', 'FAVORITE_PRODUCT_CARD')
  anchor.classList.add('favorites-list__item__url')
  img.classList.add('favorites-list__item__img')
  brand.classList.add('favorites-list__item__brand')
  brand.setAttribute('data-testid', 'FAVORITE_PRODUCT_CARD_BRAND_NAME')
  npkid.classList.add('favorites-list__item__npkid')
  brandLogo.classList.add('favorites-list__item__brand-logo')

  btnWrap.classList.add('favorites-list__item__buttons')
  btnInfo.classList.add('button')
  btnInfo.classList.add('button-sm')
  btnInfo.classList.add('button-neutral')

  tickWrapper.classList.add('favorites-list__tick')
  tickMarkUse.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href',
    '#tick')
  deleteBtn.classList.add('favorites-list__item__delete')
  deleteBtn.setAttribute('data-testid', 'FAVORITE_PRODUCT_CARD_REMOVE_ITEM')
  deleteBtn.dataset.index = itemIndex

  // Listeners
  deleteBtn.addEventListener('click', deleteItemListener)
  // Get stuff from the favorites object and set it on elements
  anchor.href = favorite.prodUrl
  btnInfo.href = favorite.prodUrl
  // btnAction.href = favorite.prodUrl;
  img.src = favorite.prodImg
  brandLogo.src = favorite.prodBrandLogo

  // Content of elements
  brand.appendChild(d.createTextNode(favorite.prodBrand))
  npkid.appendChild(d.createTextNode(favorite.prodId))
  btnInfo.appendChild(d.createTextNode(wordbook.favoritesMoreInfo))

  tickWrapper.appendChild(tickMarkSvg)
  tickMarkSvg.appendChild(tickMarkUse)

  // Build the item
  listItem.appendChild(deleteBtn)
  if (favorite.prodBrandLogo) {
    listItem.appendChild(brandLogo)
  }
  deleteBtn.appendChild(deleteBtnSvg)
  deleteBtnSvg.appendChild(deleteBtnUse)
  listItem.appendChild(anchor)
  anchor.appendChild(img)
  listItem.appendChild(brand)
  listItem.appendChild(npkid)
  listItem.appendChild(btnWrap)
  btnWrap.appendChild(btnInfo)

  // check if new cof, if so it creates the add btn in the DOM.
  if (isNewCof) {
    var btnAction = d.createElement('a')
    var btnActionLabel = d.createElement('span')
    btnAction.classList.add('button')
    btnAction.classList.add('button-sm')
    btnAction.classList.add('button-action')
    btnAction.classList.add('js-add-to-cart')
    btnAction.classList.add('favorits-list__add-to-cart')
    btnAction.dataset.npkid = favorite.prodId
    setupAddBtn(btnAction)
    btnActionLabel.appendChild(d.createTextNode(wordbook.favoritesAddToBasket))
    btnAction.appendChild(btnActionLabel)
    btnAction.appendChild(tickWrapper)
    btnWrap.appendChild(btnAction)
  }

  // Add trash can icon
  deleteBtnUse.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href',
    '#trash-can')

  return listItem
}

function deleteItemListener (event) {
  let index = event.target.dataset.index
  deleteFavorite(index)
}

function doesItemExists (npkId) {
  for (var i = 0; i < itemsArray.length; i++) {
    if (itemsArray[i].prodId === npkId) return i
  }
  return -1
}

function saveFavorite (favorite) {
  populateItems()
  let itemIndex = doesItemExists(favorite.prodId)

  if (itemIndex > -1) {
    deleteFavorite(itemIndex)
  } else {
    itemsArray.push(favorite)
    if (localStore.set('favoriteslist', JSON.stringify(itemsArray))) PubSub.publish(Keys.FAVORITE_ADDED, true)
  }
  countItems()
}

function deleteFavorite (index) {
  let deletedItemId = itemsArray[index].prodId
  itemsArray.splice(index, 1)

  if (localStore.set('favoriteslist', JSON.stringify(itemsArray))) {
    let storageEvent = new window.CustomEvent('storageSrp', {
      bubbles: true,
      cancelable: true
    })
    window.dispatchEvent(storageEvent)
  }
  renderList()

  PubSub.publish(Keys.FAVORITE_REMOVED, deletedItemId)
  // Close the list if its empty
  if (itemsArray.length < 1) {
    listWrapper.classList.remove('show-list')
    enableBodyScroll()
  }
  countItems()
}

function countItems () {
  let itemsArrayAmount = itemsArray.length
  let amountSpan = queryByClass('js-favorites-amount')
  amountSpan.innerHTML = itemsArrayAmount

  if (itemsArrayAmount === 0) {
    favoritesWidget.classList.remove('active')
    favoritesWidget.removeEventListener('click', openList)
  } else {
    favoritesWidget.classList.add('active')
    favoritesWidget.addEventListener('click', openList)
  }
}

export default init

export {
  saveFavorite
}
