import {
  queryByClass, queryAllByClass
} from '../shared/util'
import Axios from "axios/dist/axios";
const LOADING_CLASS = 'loading'
const RENDERED_CLASS = 'rendered'

let videoOverlay = document.createElement('div')
let videoPlaceHolder = document.createElement('div')
let element = queryByClass('video-overlay')

function init () {
    let pbVideoElementList = queryAllByClass('js-custom-page__video-wrapper')
    if (pbVideoElementList) {
      for (let i = 0; i < pbVideoElementList.length; i++) {
        setupPbVideo(pbVideoElementList[i])
      }
    }
    let videoWrapperElementList = queryAllByClass('js-video-overlay')
  if (videoWrapperElementList) {
    if (!element) {
      createVideoOverlay()
      for (let i = 0; i < videoWrapperElementList.length; i++) {
        getBannerElem(videoWrapperElementList[i])
      }
    }
  }
}

function setupClose (overlayClose, videoOverlay) {
  overlayClose.addEventListener('click', () => {
    unRenderVideo()
    videoOverlay.classList.remove('show-gallery')
    document.body.classList.remove('modal-dialog-open')
  })
  videoOverlay.addEventListener('click', () => {
    unRenderVideo()
    videoOverlay.classList.remove('show-gallery')
    document.body.classList.remove('modal-dialog-open')
  })
}
export function createVideoOverlay () {
  let overlayClose = document.createElement('div'),
    overlayDetailsOuter = document.createElement('div'),
    overlayDetailsInner = document.createElement('div')
  if (element === undefined) {
    document.body.appendChild(videoOverlay)
    videoOverlay.classList.add('gallery-full')
    videoOverlay.classList.add('video-overlay')
    overlayClose.classList.add('gallery-full__close')
    overlayClose.classList.add('js-gallery-close')
    overlayClose.innerHTML = '&times;'
    overlayDetailsOuter.classList.add('gallery__details__outer')
    overlayDetailsInner.classList.add('gallery-full__details')
    overlayDetailsInner.classList.add('video-overlay__container')
    videoOverlay.appendChild(overlayClose)
    videoOverlay.appendChild(overlayDetailsOuter)
    overlayDetailsOuter.appendChild(overlayDetailsInner)
    overlayDetailsInner.appendChild(videoPlaceHolder)
    setupClose(overlayClose, videoOverlay)
  }
}
export function getBannerElem (banner) {
  banner.addEventListener('click', function (event) {
    event.preventDefault()
    videoOverlay.classList.add('show-gallery')
    document.body.classList.add('modal-dialog-open')
    renderVideo(event.currentTarget.dataset)
  })
}

function setupPbVideo (videoElement) {
  let videoId = videoElement.getAttribute('data-id')
    Axios.get('/api/video/get-video-info/'+ videoId)
        .then((result) => {
            if (result.data && result.data.sourceUrl) {
              videoElement.setAttribute('data-api', result.data.sourceUrl)
              let videoThumb = videoElement.querySelector('.js-custom-page__video-desktop-thumbnail')
              if (!videoThumb.getAttribute('src')) {
                videoThumb.setAttribute('src',result.data.thumbnailUrl+'/medium')
              }
            }
        })
}

function renderVideo (data) {
  videoPlaceHolder.classList.add(LOADING_CLASS)
  videoPlaceHolder.classList.add(RENDERED_CLASS)
  const fragment = document.createDocumentFragment()
  const container = document.createElement('div')
  container.classList.add('js-iframe')
  container.style.cssText = 'width:100%; height:0; position: relative; padding-bottom:56.24999999296875%'
  const iframe = document.createElement('iframe')
  iframe.src = `${data.api}`
  iframe.allowFullscreen = true
  iframe.mozallowfullscreen = true
  iframe.webkitallowfullscreen = true
  iframe.frameBorder = '0'
  iframe.border = '0'
  iframe.scrolling = 'no'
  iframe.gesture = 'media'
  iframe.style.cssText = 'width:100%; height:100%; position: absolute; top: 0; left: 0;'
  container.appendChild(iframe)
  fragment.appendChild(container)
  videoPlaceHolder.appendChild(fragment)
}

function unRenderVideo () {
  const iframeBox = queryByClass('js-iframe')
  if (iframeBox) {
    videoPlaceHolder.removeChild(iframeBox)
    videoPlaceHolder.classList.remove(RENDERED_CLASS)
  }
}
export default init
