import { queryByClass } from '../shared/util';
import { pushGtmEvent } from '../shared/gtm-tracking.js';
import Axios from 'axios/dist/axios'
let replacementBtn1, replacementBtn2, replacementStep1, replacementStep2 , replacementStep3, form;

function init() {
  replacementBtn1 = queryByClass('js-replacement-btn1');
  replacementBtn2 = queryByClass('js-replacement-btn2');
  replacementStep1 = queryByClass('js-replacement-step1');
  replacementStep2 = queryByClass('js-replacement-step2');
  replacementStep3 = queryByClass('js-replacement-step3');
  form = queryByClass('js-replacement-form');
  if (replacementBtn1) {
    replacementBtn1.addEventListener('click', () => {
      replacementStep1.classList.toggle('active');
      replacementStep2.classList.toggle('active');
    });

    form.addEventListener('submit', function (event) {
      event.preventDefault();
      submitRequest(event.target);
    })
  }
}

function submitRequest(fields) {
  const customerEmail = fields.email.value;
  const content = fields.text.value;
  let preloader = queryByClass('js-preloader');

  const config = {
    auth: {
      username: 'opticon_replacement_auth',
      password: 'hjf8a6w67efh3j'
    }
  }

  let data = new window.FormData()
  data.append('customerEmail', customerEmail)
  data.append('content', content)
  preloader.classList.toggle('show-loader');

  Axios.post('/api/v1/wagmails/trigger/ReplacementProductMail', data, config)
    .then(() => {
      replacementStep2.classList.toggle('active');
      replacementStep3.classList.toggle('active');
      preloader.classList.toggle('show-loader');
      pushGtmEvent('global_replacement_form_submit');
    })
}

export default init;
