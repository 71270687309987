import { datadogRum } from "@datadog/browser-rum";
import _ from "lodash";
import {
  getSplitEnvironmentFromSiteMode,
  getScopeFromShopId
} from "./search-utils";
import { getCookiesAsObject, cookieChangeEventName } from "./cookieCheck";

const consentsApprovedCookiePath = "CookieInformationConsent.consents_approved";
const monitoringCookie = "cookie_cat_statistic";

const applicationId = "65e3396e-aba5-4f74-8769-281848e0bcf8";
const clientToken = "pub1590cd54da839e26d956dd8ed920b179";
const service = "wag-shop";
const env = getSplitEnvironmentFromSiteMode(window.site_mode);
const shopScope = getScopeFromShopId(window.shop_id);
const datadogConfig = {
  applicationId,
  clientToken,
  site: "datadoghq.com",
  service,
  sampleRate: 5,
  sessionReplaySampleRate: 5,
  trackInteractions: true,
  defaultPrivacyLevel: "mask-user-input",
  env
};

let hasStartedMonitoring = false;

function initDatadogMonitoring() {
  try {
    datadogRum.init(datadogConfig);
    datadogRum.addRumGlobalContext("brand", shopScope);
    datadogRum.startSessionReplayRecording();
    hasStartedMonitoring = true;
  } catch (error) {
    console.log("Unable to initialize monitoring", error);
  }
}

export function initMonitoring() {
  document.addEventListener(cookieChangeEventName, function({ detail }) {
    const { listenForCookieChange, hasCookieChanged } = detail;
    listenForCookieChange();
    if (hasCookieChanged) {
      const parsedCookie = getCookiesAsObject();
      const consentsArray = _.get(parsedCookie, consentsApprovedCookiePath, []);
      const hasMonitoringConsent = _.includes(consentsArray, monitoringCookie);
      if (hasMonitoringConsent && !hasStartedMonitoring) {
        initDatadogMonitoring();
      }
    }
  });
}
export default initMonitoring;
