import {
  queryAllByClass
} from '../shared/util'
import {
  isEmail
} from '../shared/validators'
import Axios from 'axios/dist/axios'
import Marked from 'marked';

function init() {
  const forms = queryAllByClass('js-newsletter-signup')
  const valdationMsgs = queryAllByClass('js-newsletter-signup__validation')

  for (let i = 0; i < forms.length; i++) {
    forms[i].addEventListener('submit', function (event) {
      event.preventDefault()
      submitNewsletter(event.target, valdationMsgs[i])
    })
  }

  const mcEmbedded = document.getElementById('mc-embedded-subscribe-form');
  if (mcEmbedded) {
    mcEmbedded.addEventListener('submit', function (event) {
      const email = mcEmbedded.querySelector('input[name="EMAIL"]');
      if (email) {
        // For now, fire and forget
        subscribeNewsletter(email.value, null, () => {}, () => {}, true);
      }
    });
  }
}

function subscribeNewsletter(email, name, successCallback, errorCallback, onlyMapp) {
  const config = {
    auth: {
      username: 'pagebuilder_newsletter',
      password: 'MvkrC5r3'
    }
  }
  const signupSource = window.location.pathname.substr(window.location.pathname.lastIndexOf('/') + 1)

  let data = new window.FormData()
  data.append('email', email)
  data.append('name', name)
  data.append('signup_source', signupSource)

  if (onlyMapp) {
    data.append('only_mapp', 'true');
  }
  
  if (isEmail(email) && name !== '') {
    Axios.post('/api/v1/newsletters/signup', data, config).then(successCallback);
  } else {
    errorCallback();
  }
}

function submitNewsletter(fields, valMsg) {
  const email = fields.email.value
  const name = fields.name.value

  valMsg.classList.remove('error')
  valMsg.classList.remove('validated')
  valMsg.innerHTML = ''

  subscribeNewsletter(
    email,
    name,
    () => {
      valMsg.classList.add('validated')
      valMsg.innerHTML = Marked(window.wordbookObject.newsletterValidated)
    },
    () => {
      valMsg.classList.add('error')
      valMsg.innerHTML = Marked(window.wordbookObject.newsletterValidationError)
    }
  );
}

export default init