import {
    queryByClass,
    queryAllByClass
} from '../shared/util'
import { sessionStore } from '../shared/storage'

let chatTriggers
let focalScopeId
let focalScopeGroup
let preloader

const CHAT_LOADED = 'chatLoaded'
const CHAT_OPEN = 'chatOpen'

function init () {
  chatTriggers = queryAllByClass('js-chat-trigger')
  preloader = queryByClass('js-preloader')
  if (!chatTriggers.length) return
  focalScopeId = window.focalscope_id
  focalScopeGroup = window.focalscope_group
  if(sessionStore.get(CHAT_LOADED)){
    getFocalscope()
  }
  chatTriggers.forEach(trigger => {
    trigger.addEventListener('click', () => {
      handleClick();
  })})
}



function getFocalscope () {
  let embedFocalscope = document.createElement('script')
  embedFocalscope.addEventListener('load', () => {
    focalscopeSettings()
  })
  embedFocalscope.src = `https://wag.focalscope.com/lchat/script/FocalScopeChatLoad.js?__v=${focalScopeId}`
  document.body.appendChild(embedFocalscope)
}

function focalscopeSettings () {
  window.$FSLC('body').FocalScopeChat({
    group: focalScopeGroup,
    lang: '__',
    fShowRegForm: true
  })
  chatOpenTrigger()
}

function chatOpenTrigger () {
  let buttonChecker = setInterval(checkForButton, 100)

  function checkForButton () {
    // Floating button in Focalscope Templates
    let chatButtonOpen = queryByClass('focalFloatButton')
    if (chatButtonOpen) {
      if (sessionStore.get(CHAT_OPEN)) {
        chatButtonOpen.click()
      }
      chatButtonOpen.addEventListener('click', () => {
        sessionStore.set(CHAT_OPEN, 1)
      })
      focalScopeCloseChat()
      focalScopeCloseOfflineBox()
      clearInterval(buttonChecker)
      chatTriggers.forEach(() => {
        preloader.classList.remove('show-loader')
      })
    }
  }
}

// Button closer in Focalscope Chat tempalte
function focalScopeCloseChat () {
  let closeChatButton = queryByClass('focalLayerHeaderCloseButton')
  closeChatButton.addEventListener('click', () => {
    sessionStore.remove(CHAT_OPEN)
  })
}

// Button closer in Focalscope Offline message tempalte
function focalScopeCloseOfflineBox () {
  let closeOfflineBoxButton = queryByClass('focalFloatQuickOfflineMessageCloseBut')
  closeOfflineBoxButton.addEventListener('click', () => {
    sessionStore.remove(CHAT_OPEN)
  })
}
function handleClick () {
  if (sessionStore.get(CHAT_LOADED)) {
    sessionStore.set(CHAT_OPEN, 1)
    chatOpenTrigger()
  } else {
    getFocalscope()
    preloader.classList.add('show-loader')
    sessionStore.set(CHAT_LOADED, 1)
    sessionStore.set(CHAT_OPEN, 1)
  }
}

export default init
