import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { sessionStore } from "../shared/storage";

const SearchServiceUrlStaging =
  "https://search-service-api-test.wag.tools/search/b2c";
const SearchServiceUrlProduction =
  "https://search-service-api.wag.tools/search/b2c";

const getSearchServiceUrl = (env) => {
  if (env === "production") {
    return SearchServiceUrlProduction;
  }
  return SearchServiceUrlStaging;
};

const getSplitEnvironmentFromSiteMode = (site_mode) => {
  return site_mode === "LIVE" ? "production" : "staging";
};

const searchTrackingEvent = (event, action, payload) => {
  window.dataLayer.push({
    event,
    action,
    label: JSON.stringify(payload),
    category: "search",
  });
};

const scopes = [
  "DK",
  "SE",
  "NO",
  "Skou",
  "SkouNO",
  "TretSE",
  "TretNO",
  "Enemo",
  "LPH",
  "Bilka",
  "Wupti",
  "Foetex",
];
const getScopeFromShopId = (shopId) => {
  const lowercaseShopId = _.toLower(shopId);
  const lowercaseScopes = _.map(scopes, _.toLower);
  const index = _.findIndex(
    lowercaseScopes,
    (lowercaseScope) => lowercaseScope === lowercaseShopId
  );
  return scopes[index !== -1 ? index : 0];
};

const searchSplitEnabled = window.searchSplitEnabled === "1";

const SESSION_SEARCH_TEMPORARY_ID = "search_temporary_id";
function getSessionTemporaryId() {
  const existingId = sessionStore.get(SESSION_SEARCH_TEMPORARY_ID);
  if (existingId) return existingId;

  const newId = uuidv4();
  sessionStore.set(SESSION_SEARCH_TEMPORARY_ID, newId);
  return newId;
}

export {
  getScopeFromShopId,
  getSearchServiceUrl,
  getSplitEnvironmentFromSiteMode,
  searchTrackingEvent,
  getSessionTemporaryId,
  searchSplitEnabled,
};
