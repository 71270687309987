import Keys from '../shared/pubsub-keys'
import {
  queryByClass,
} from '../shared/util'
import {
  sessionStore,
  localStore
} from '../shared/storage'
import CartService from './cart-service'

let basket, basketIcon, basketAmount, isNewCOF
const FULL_CLASS = 'full'
const ANIMATION_CLASS = 'do-animation'
const CART_STORE_KEY = window.shop_id + Keys.CART

export default function init() {
  isNewCOF = sessionStore.getJSON('onePageCof')
  queryDom()
  const isConfirmationPage = !!queryByClass('js-cof-complete')
  parseClearCartParam()

  if (isConfirmationPage) {
    clearCart()
    return
  }

  setupCart(getCart())
  setupEventListeners()
}

function updateBasket(event) {
  let cart = getCart() || {}
  if (cart.count === event.detail) {
    return
  }
  if (event.detail !== null) {
    cart.count = event.detail
    setCart(cart)
  }
  setProductCountInHeader(cart.count)
}

function storageListener(event) {
  if (event.key === CART_STORE_KEY) {
    updateBasket({
      detail: null
    })
  }
}

function queryDom() {
  basket = queryByClass('js-basket-widget')
  basketIcon = queryByClass('js-basket-icon', basket)
  basketAmount = queryByClass('js-basket-amount', basket)
}

function parseClearCartParam() {
  let rex = new RegExp(/.*action=(\w*)&?.*/)
  const matches = rex.exec(window.location.search)
  if (matches && matches[1] === 'clear_cart') clearCart()
}

function isCartOld(cart) {
  return (Date.now() - cart.created) > 86400000 // older than 24 h
}

function setupEventListeners() {
  basketIcon.addEventListener('animationend', () => {
    basketIcon.classList.remove(ANIMATION_CLASS)
  }, false)

  basketAmount.addEventListener('animationend', () => {
    basketAmount.classList.remove(ANIMATION_CLASS)
  }, false)
  window.addEventListener('storage', storageListener)
  window.addEventListener('basketCount', updateBasket)
}

function setupCart(cart) {
  if (!sessionStore.getJSON('onePageCof')) {
    setUpLegacyCart(cart)
    return
  }

  if (!cart) return

  if (!isCartOld(cart)) {
    let count = cart.guid ? cart.count : 0
    if (cart.type === 'quote') {
      count = count > 1 ? 1 : count
    }
    setProductCountInHeader(count)
  } else {
    localStore.remove('cof_zip');
    createNewCart(cart)
  }
}

function setUpLegacyCart(cartFromStorage) {
  let cart = cartFromStorage || {}
  cart.count = basket.dataset.amount
  setProductCountInHeader(cart.count)
  setCart(cart)
}

function setCountInStorage(count) {
  let basket = getCart() || {}
  basket.count = count
  setCart(basket)
  window.dispatchEvent(new window.CustomEvent('basketCount', {
    'bubbles': true,
    'cancelable': true
  }))
}

export function setProductCountInHeader(count) {
  if (!basket) {
    queryDom()
  }
  if (count > 0) {
    basketAmount.innerHTML = count
    basket.classList.add(FULL_CLASS)
    basketIcon.classList.add(ANIMATION_CLASS)
    basketAmount.classList.add(ANIMATION_CLASS)
  } else {
    basket.classList.remove(FULL_CLASS)
  }
}

export function createNewCart() {
  return new Promise((resolve) => {
    CartService.getCart()
      .then(res => {
        const newCart = {
          guid: res.guid,
          type: res.creationType,
          created: Date.now(),
          count: 0
        }
        setProductCountInHeader(0)
        setCart(newCart)
        resolve(res.guid)
      })
  })
}

export function setBasketCount() {
  if (isNewCOF) {
    CartService.getCount()
      .then((res) => {
        setCountInStorage(res.totals.products.amount)
      })
  } else {
    let cart = getCart()
    let currentCount = cart ? +cart.count : 0
    setCountInStorage(currentCount + 1)
  }
}

export function getCartId() {
  return new Promise((resolve) => {
    let cartFromStorage = getCart()
    if (cartFromStorage && cartFromStorage.hasOwnProperty('guid') &&
      cartFromStorage.type !== 'quote') {
      resolve(cartFromStorage.guid)
    } else {
      createNewCart()
        .then(res => {
          resolve(res)
        })
    }
  })
}

export function getCart() {
  return localStore.getJSON(CART_STORE_KEY)
}

export function setCart(cart) {
  localStore.set(CART_STORE_KEY, JSON.stringify(cart))
}

export function clearCart() {
  localStore.remove(CART_STORE_KEY)
}
