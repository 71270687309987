let printBtn

function init () {
  printPage()
}

function printPage () {
  printBtn = document.querySelector('.js-print')
  if (!printBtn) return
  printBtn.addEventListener('click', () => {
    window.print()
  })
}

export default init
