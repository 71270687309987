import {
  queryByClass
} from '../shared/util'

let inhouseNavTrigger, inhouseNav, showPricesButton, hidePricesButton

const SHOW_NAV = 'show-inhouse-nav'

function init () {
  showInhouseNav()

  showPricesButton = queryByClass('js-b2b-show-prices')
  hidePricesButton = queryByClass('js-b2b-hide-prices')

  if (showPricesButton) {
    showPricesButton.addEventListener('click', () => {
      showOrHidePrices(0)
    })
  }
  if (hidePricesButton) {
    hidePricesButton.addEventListener('click', () => {
      showOrHidePrices(1)
    })
  }
}

function showInhouseNav () {
  inhouseNavTrigger = document.querySelector('.js-inhouse-nav-trigger')
  if (!inhouseNavTrigger) return

  inhouseNav = document.querySelector('.js-inhouse-nav')
  if (!inhouseNav) return

  inhouseNavTrigger.addEventListener('click', () => {
    inhouseNav.classList.toggle(SHOW_NAV)
    inhouseNavTrigger.classList.toggle(SHOW_NAV)
  })
}

function showOrHidePrices (showPrices) {
  if (window.location.href.indexOf('search_result') > -1) {
    let urlStripped = window.location.href.split('#')[0].split('&')[0]
    window.location.href = urlStripped + '&hide_prices=' + showPrices + window.location
      .hash
  } else {
    window.location.href = window.location.pathname + '?hide_prices=' +
      showPrices + window.location.hash
  }
}

export default init
