import CartService from '../basket/cart-service'
import Keys from '../shared/pubsub-keys'
import { sessionStore } from '../shared/storage'

export default function init () {
  const qliroRoot = document.getElementById('qliro-extrapay')
  if (!qliroRoot) return

  sessionStore.set(Keys.CART_ID, qliroRoot.dataset.cartGuid)

  CartService.getQliroIFrame()
  .then(res => {
    if (res.iframe) {
      const scriptFrag = document.createRange().createContextualFragment(res.iframe)
      qliroRoot.appendChild(scriptFrag)
    }
  })
}
