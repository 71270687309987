import scrollTo from "../shared/animated-scroll";
import { queryAllByClass } from "../shared/util";

function init() {
  const anchors = queryAllByClass("js-goto-section");
  anchors.forEach(link => {
    const section = document.getElementById(link.dataset.section);
    link.addEventListener("click", event => {
      const scrollTop = window.scrollY + section.getBoundingClientRect().top;
      scrollTo(scrollTop, 500, "easeInOutQuint");
      event.preventDefault();
    });
  });
}

export default init;
