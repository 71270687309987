import {
  queryByClass,
  queryAllByClass
} from '../shared/util'
import PubSub from 'pubsub-js'
import Keys from '../shared/pubsub-keys'

const SHOW_MENU_CLASS = 'show-menu'
const TRIGGER_CLOSE = 'trigger-close'
const LVL_2_CLASS = 'lvl2-visible'
const LVL_3_CLASS = 'lvl3-visible'
const DRAGGING_CLASS = 'dragging'
const CLOSE_DELAY = 500

let menuTrigger,
  menuWrapper,
  lvl1Items,
  lvl2Items,
  lvl1Menu,
  lvl2Menus,
  lvl3Menus,
  backLinksLvl2,
  backLinksLvl3,
  contentBlock,
  contentOverlay,
  menuOpen,
  activeLvl2,
  activeLvl3,
  dragging,
  dragStart,
  dragEnd,
  openPosition

function init () {
  PubSub.subscribe(Keys.SEARCH_ACTIVE, closeMenu)
  menuTrigger = queryByClass('js-menu-trigger')
  contentBlock = queryByClass('js-content')
  contentOverlay = queryByClass('js-off-canvas-overlay', contentBlock)
  menuWrapper = queryByClass('js-off-canvas')
  lvl1Items = queryAllByClass('js-off-canvas-item', menuWrapper)
  lvl2Items = queryAllByClass('js-off-canvas-item-lvl2', menuWrapper)
  lvl1Menu = queryByClass('js-off-canvas-lvl1', menuWrapper)
  lvl2Menus = queryAllByClass('js-off-canvas-lvl2', menuWrapper)
  lvl3Menus = queryAllByClass('js-off-canvas-lvl3', menuWrapper)
  backLinksLvl2 = queryAllByClass('js-off-canvas-back-lvl2', menuWrapper)
  backLinksLvl3 = queryAllByClass('js-off-canvas-back-lvl3', menuWrapper)

  setupMenu()
}

function setupMenu () {
  openPosition = menuWrapper.offsetWidth * -1

  closeMenu()
  menuTrigger.addEventListener('click', menuTriggerClickListener)
  lvl1Items.forEach((item) => {
    item.addEventListener('click', lvl1Click)
  })
  lvl2Items.forEach((item) => {
    item.addEventListener('click', lvl2Click)
  })
  backLinksLvl2.forEach((item) => {
    item.addEventListener('click', backClickLvl2)
  })
  backLinksLvl3.forEach((item) => {
    item.addEventListener('click', backClickLvl3)
  })
  contentOverlay.addEventListener('touchstart', onTouchStartListener)
  contentOverlay.addEventListener('touchmove', onTouchMoveListener)
  contentOverlay.addEventListener('touchend', onTouchEndListener)
}

function menuTriggerClickListener (event) {
  event.preventDefault()
  if (menuOpen) {
    closeMenu()
  } else {
    window.requestAnimationFrame(() => {
      menuTrigger.classList.add(TRIGGER_CLOSE)
      contentBlock.classList.add(SHOW_MENU_CLASS)
      lvl1Menu.classList.add(SHOW_MENU_CLASS)
      document.body.classList.add(SHOW_MENU_CLASS)
    })

    PubSub.publish(Keys.MENU_OPEN)
    menuOpen = true
  }
}

function onTouchStartListener (event) {
  dragging = true
  dragStart = {
    x: event.touches[0].clientX,
    y: event.touches[0].clientY
  }
  contentBlock.classList.add(DRAGGING_CLASS)
}

function onTouchMoveListener (event) {
  if (dragging) {
    dragEnd = {
      x: event.touches[0].clientX,
      y: event.touches[0].clientY
    }

    event.preventDefault()

    let position = openPosition + (dragEnd.x - dragStart.x)
    contentBlock.style.transform = `translate3d(${position}px, 0, 0)`
  }
}

function onTouchEndListener (event) {
  event.preventDefault()
  contentBlock.classList.remove(DRAGGING_CLASS)
  contentBlock.style.transform = null
  closeMenu()
  dragging = false
}

function lvl1Click (event) {
  let id = event.target.dataset.menuid
  event.preventDefault()

  menuWrapper.classList.add(LVL_2_CLASS)
  lvl2Menus.forEach((menu) => {
    if (menu.dataset.menuid === id) {
      menu.classList.add(SHOW_MENU_CLASS)
      activeLvl2 = menu
    }
  })
}

function lvl2Click (event) {
  const id = event.target.dataset.menuid
  const parentId = event.target.dataset.parentid
  event.preventDefault()

  lvl3Menus.forEach((menu) => {
    if (menu.dataset.menuid === id && menu.dataset.parentid === parentId) {
      menu.classList.add(SHOW_MENU_CLASS)
      activeLvl3 = menu
    }
  })
  menuWrapper.classList.add(LVL_3_CLASS)
}

function backClickLvl2 (event) {
  event.preventDefault()
  menuWrapper.classList.remove(LVL_2_CLASS)
  window.setTimeout(() => {
    if (activeLvl2) activeLvl2.classList.remove(SHOW_MENU_CLASS)
    activeLvl2 = undefined
  }, CLOSE_DELAY)
}

function backClickLvl3 (event) {
  event.preventDefault()
  menuWrapper.classList.remove(LVL_3_CLASS)
  window.setTimeout(() => {
    if (activeLvl3) activeLvl3.classList.remove(SHOW_MENU_CLASS)
    activeLvl3 = undefined
  }, CLOSE_DELAY)
}

function closeMenu () {
  menuTrigger.classList.remove(TRIGGER_CLOSE)
  contentBlock.classList.remove(SHOW_MENU_CLASS)
  menuWrapper.classList.remove(LVL_2_CLASS)
  menuWrapper.classList.remove(LVL_3_CLASS)
  document.body.classList.remove(SHOW_MENU_CLASS)

  window.setTimeout(() => { // allow menu to close before reseting visibility
    lvl1Menu.classList.remove(SHOW_MENU_CLASS)
    if (activeLvl2) activeLvl2.classList.remove(SHOW_MENU_CLASS)
    if (activeLvl3) activeLvl3.classList.remove(SHOW_MENU_CLASS)
    activeLvl2 = undefined
    activeLvl3 = undefined
  }, CLOSE_DELAY)

  menuOpen = false
}

export default init
