import {
  queryByClass,
  queryAllByClass
} from '../shared/util'
import {
  sessionStore, localStore
} from '../shared/storage'
import {
  getBreakPoint,
  BREAKPOINTS
} from '../shared/breakpoints'

const STORAGE_KEY = 'takeover-shown'
const SHOW_CLASS = 'show'
const DELAY = 2000
const SUSPENSION_TIME = 1800000 // half an hour

let takeover, imgBackground, closeLinks

function init () {
  takeover = queryByClass('js-takeover')
  if (!takeover) return
  imgBackground = queryByClass('js-takeover-img', takeover)
  closeLinks = queryAllByClass('js-takeover-close', takeover)

  if (shouldShow()) {
    setImgSource()
    showTakeover()
    addCloseHandlers()
  }
}

function addCloseHandlers () {
  closeLinks.forEach((link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault()
      hideTakeover()
    })
  })
}

function showTakeover () {
  window.setTimeout(() => {
    takeover.classList.add(SHOW_CLASS)
    sessionStore.set(STORAGE_KEY, '1')
    localStore.set(STORAGE_KEY, Date.now())
  }, DELAY)
}

function hideTakeover () {
  takeover.classList.remove(SHOW_CLASS)
}

function shouldShow () {
  if (document.body.classList.contains('manufactor__page')) {
    return false
  }
  if (sessionStore.get(STORAGE_KEY)) {
    return false
  }
  let timestamp = localStore.get(STORAGE_KEY)
  if (timestamp) {
    return (Date.now() - new Date(parseInt(timestamp))) > SUSPENSION_TIME
  }
  return true
}

function setImgSource () {
  let breakPoint = getBreakPoint()
  let isMobile = breakPoint === BREAKPOINTS.MOBILE || breakPoint === BREAKPOINTS.MOBILE_RETINA
  let bgUrl = isMobile ? imgBackground.dataset.mobileImg : imgBackground.dataset.desktopImg
  imgBackground.style.backgroundImage = `url(${bgUrl})`
}

export default init
