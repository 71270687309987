import {
  queryByClass
} from '../shared/util'

let countDownWrap
let countDownDate
let flipperDays
let flipperHours
let flipperMinutes
let flipperSeconds
let countDownSimple
let countDownVipWrapper
const ANIMATE_CLASS = 'flipperAnimate'

function init() {
  countDownWrap = queryByClass('js-countdown')
  countDownSimple = queryByClass('js-countdown-simple')
  countDownVipWrapper = queryByClass('js-delivery-clock')
  if (!countDownWrap) return

  flipperDays = queryByClass('js-flipper-days', countDownWrap)
  flipperHours = queryByClass('js-flipper-hours', countDownWrap)
  flipperMinutes = queryByClass('js-flipper-minutes', countDownWrap)
  flipperSeconds = queryByClass('js-flipper-seconds', countDownWrap)
  countDownDate = new Date(countDownWrap.dataset.countdownDate.replace(/-/g, "/")).getTime()
  countDown()
}

function countDown() {
  // Update the count down every 1 second
  let countdownInterval = setInterval(function () {
    // Get todays date and time
    let now = new Date().getTime()

    // Find the distance between now an the count down date
    let distance = countDownDate - now

    // Time calculations for days, hours, minutes and seconds
    let days = Math.floor(distance / 86400000)
    let hours = Math.floor((distance % 86400000) / 3600000)
    let minutes = Math.floor((distance % 3600000) / 60000)
    let seconds = Math.floor((distance % 60000) / 1000)

    // Stringify  days, hours, minutes and seconds
    let daysToStr = days.toString()
    let hoursToStr = hours.toString()
    let minutesToStr = minutes.toString()
    let secondsToStr = seconds.toString()

    // Add leading 0 if length is shorter than 2
    let hoursDisplay = false
    if (hoursToStr.length < 2) {
      hoursDisplay = '0' + hoursToStr
    } else {
      hoursDisplay = hoursToStr
    }

    let minutesDisplay = false
    if (minutesToStr.length < 2) {
      minutesDisplay = '0' + minutesToStr
    } else {
      minutesDisplay = minutesToStr
    }

    let secondsDisplay = false
    if (secondsToStr.length < 2) {
      secondsDisplay = '0' + secondsToStr
    } else {
      secondsDisplay = secondsToStr
    }

    //Countdown flipper layout
    if (countDownSimple) {
      flipperHours.innerHTML = hoursDisplay
      flipperMinutes.innerHTML = minutesDisplay
      flipperSeconds.innerHTML = secondsDisplay
    } else {
      flipperDays.innerHTML = daysToStr
      flipperHours.innerHTML = hoursDisplay
      flipperMinutes.innerHTML = minutesDisplay
      flipperSeconds.innerHTML = secondsDisplay
    }


    // Animate minutes
    if (seconds == '59') {
      flipperMinutes.classList.add(ANIMATE_CLASS)
    } else {
      flipperMinutes.classList.remove(ANIMATE_CLASS)
    }

    // Animate hours
    if ((minutes == '59') && (seconds == '59')) {
      flipperHours.classList.add(ANIMATE_CLASS)
    } else {
      flipperHours.classList.remove(ANIMATE_CLASS)
    }

    // Animate days
    if ((hours == '59') && (minutes == '59') && (minutes == '59')) {
      flipperDays.classList.add(ANIMATE_CLASS)
    } else {
      flipperDays.classList.remove(ANIMATE_CLASS)
    }

    // If the count down is over, hide it
    if (distance < 0) {
      window.clearInterval(countdownInterval)
      if (countDownSimple) {
        countDownVipWrapper.style.display = 'none';
      }
    }
  }, 1000)
}

export default init
